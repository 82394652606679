:root {
  --left-table-column-width: 120px;
  --header-height: 140px;
  --footer-height: 108px;
}
#claimButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
main > div {
  /* border-collapse: collapse; */
  /* width: 100%; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
table {
  border-collapse: collapse;
}
table tr {
  /* width: 100%; */
  /* border: 1px solid; */
}

table a:hover,
table a:hover > div {
  color: #fff;
}
table #controls {
  max-width: 320px;
  display: none;
}
table #controls > button {
  flex: 1;
  padding: 0 24px;
}
table aside {
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 16px;
  color: grey;
  display: inline;
  vertical-align: middle;
  text-align: center;
  padding: 16px;
}
table button {
  user-select: none;
  appearance: none;
  border: none;
  background: 0 0;
  cursor: pointer;
  outline: 0;
  height: 48px;
}
table button div {
  text-transform: uppercase;
  white-space: nowrap;
  text-rendering: geometricPrecision;
  color: #fff;
}
table hr {
  background-color: #80808040;
  height: 1px;
  border: none;
}
table svg path {
  fill: #fff;
}
table td div {
  word-break: break-word;
}
table th div {
  text-align: right;
  color: #80808080;
}
table td,
table th {
  padding: 8px;
  text-rendering: geometricPrecision;
  /* max-width: 320px; */
  line-height: 1.25;
}
table td div {
  /* width: calc(100vw - var(--left-table-column-width)); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
table td div svg ~ div {
  width: unset;
}
table tr:first-of-type > * {
  padding-top: 24px;
}
table tr:last-child td div > div {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
table tr:last-of-type > * {
  padding-bottom: 24px;
}
table tr#additional-details-border > * {
  padding: 0;
  margin: 0;
}
table[data-claim-rendered] {
  /* border: 1px solid #80808020; */
  /* border-right: 0; */
  /* border-left: 0; */
}
table[data-claim-rendered] #controls {
  display: flex;
}
table[data-claim-rendered] button {
  opacity: 0.5;
}
table[data-claim-rendered] button:disabled {
  opacity: 0;
  pointer-events: none;
}
table[data-claim-rendered] button:hover {
  background-color: #80808020;
  opacity: 1;
}
table[data-claim-rendered] button > div {
  display: none;
}
table[data-claim-rendered] button > svg.claim-icon {
  display: unset;
}
table[data-claim-rendered] button:hover > div {
  display: unset;
}
table[data-claim-rendered] button:hover > svg {
  display: none;
}
table[data-claim-rendered] button#additionalDetails {
  width: 100%;
  opacity: 0.25;
}
table[data-claim-rendered] button#additionalDetails:hover {
  opacity: 1;
}
table[data-claim-rendered] tr {
  background-color: #80808000;
  transition: 125ms ease-in-out opacity;
}
table[data-claim-rendered] tr#additional-details-border {
  background-color: #80808010;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  /* border: 1px solid #80808020; */
  /* border-left: 0; */
  /* border-right: 0; */
}
table[data-claim-rendered] tr#additional-details-border + tr > * {
  padding-top: 24px;
}
table #additional-details-border ~ tr,
table[data-details-visible="false"] #additional-details-border ~ tr {
  opacity: 0;
  pointer-events: none;
}
table[data-details-visible="false"] {
  /* border-bottom: 1px solid #80808000; */
}
table[data-details-visible="true"] #additional-details-border ~ tr {
  opacity: 1;
  pointer-events: unset;
}
table[data-details-visible="true"] {
  /* border-bottom: 1px solid #80808020; */
}
table[data-contract-loaded] #Token {
  display: none;
}
#transferDetails\.requestedAmount > a {
  color: #fff;
  font-size: 24px;
}
table[data-details-visible="false"] #permit\.permitted\.token .full,
table[data-details-visible="true"] #permit\.permitted\.token .short {
  display: none;
}
table[data-details-visible="false"] #permit\.permitted\.token .short,
table[data-details-visible="true"] #permit\.permitted\.token .full {
  display: initial;
}
table[data-details-visible="false"] #transferDetails\.to .full,
table[data-details-visible="true"] #transferDetails\.to .short {
  display: none;
}
table[data-details-visible="false"] #transferDetails\.to .short,
table[data-details-visible="true"] #transferDetails\.to .full {
  display: initial;
}
#To > td,
#To > th {
  padding-bottom: 24px;
}
table thead {
  display: table-row-group;
}
table tbody {
  display: none;
}
table[data-claim="none"] thead {
  display: table-row-group;
}
table[data-claim="error"] thead {
  display: table-row-group;
}
table[data-claim="ok"] thead {
  display: none;
}
table[data-claim="none"] tbody {
  display: none;
}
table[data-claim="error"] tbody {
  display: none;
}
table[data-claim="ok"] tbody {
  display: table-row-group;
}
/*
table[data-claim-rendered="true"][data-claim="none"][data-contract-loaded="true"][data-details-visible="false"] {
  border: none;
} */
#transferDetails\.to a div {
  opacity: 0.66;
}
#transferDetails\.to a:hover div {
  opacity: 1;
}
#transferDetails\.to div {
  color: #fff;
}
