:root {
  --toast-success: #0abf30;
  --toast-error: #e24d4c;
  --toast-warning: #e9bd0c;
  --toast-info: #3498db;
}

.fa-circle-check {
  color: var(--toast-success);
}
.fa-circle-xmark {
  color: var(--toast-error);
}
.fa-triangle-exclamation {
  color: var(--toast-warning);
}
.fa-circle-info {
  color: var(--toast-info);
}

.notifications {
  position: fixed;
  bottom: 30px;
  right: 20px;
}

.notifications :where(.toast, .column) {
  display: flex;
  align-items: center;
}

.notifications .toast {
  width: 400px;
  height: 50px;
  position: relative;
  overflow: hidden;
  list-style: none;
  border-radius: 4px;
  padding: 16px 17px;
  margin-bottom: 10px;
  justify-content: space-between;
  animation: show_toast 0.5s ease-in-out;
  border: 1px solid #80808020;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

@keyframes show_toast {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notifications .toast.hide {
  animation: hide_toast 0.5s ease-in-out;
}

@keyframes hide_toast {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.toast::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  animation: progress 5s linear forwards;
}

@keyframes progress {
  100% {
    width: 0%;
  }
}

.toast.success::before,
.btn#success {
  background: var(--toast-success);
}

.toast.error::before,
.btn#error {
  background: var(--toast-error);
}

.toast.warning::before,
.btn#warning {
  background: var(--toast-warning);
}

.toast.info::before,
.btn#info {
  background: var(--toast-info);
}

.toast .column i {
  font-size: 1.75rem;
}

.toast.success .column i {
  color: var(--toast-success);
}

.toast.error .column i {
  color: var(--toast-error);
}

.toast.warning .column i {
  color: var(--toast-warning);
}

.toast.info .column i {
  color: var(--toast-info);
}

.toast .column span {
  font-size: 1.07rem;
  margin-left: 12px;
}

.toast i:last-child {
  color: #aeb0d7;
  cursor: pointer;
}

.toast i:last-child:hover {
  filter: invert(1);
}

@media screen and (max-width: 530px) {
  .notifications {
    width: 95%;
  }

  .notifications .toast {
    width: 100%;
    font-size: 1rem;
    margin-left: 20px;
  }
}

.show-cl {
  display: block;
}

.hide-cl {
  display: none !important;
}
